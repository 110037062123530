<template>
    <v-app>
        <div class="card card-custom">
            <div class="card-body p-0">
                <div
                    class="wizard wizard-2"
                    id="kt_wizard_v2"
                    data-wizard-state="step-first"
                    data-wizard-clickable="true"
                >
                    <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
                        <div class="row">
                            <div class="col-6">
                                <h4>
                                    Academic calendar - {{ academicCalendar.title }}
                                </h4>
                                <router-link :to="{ name: 'dashboard' }">
                                Dashboard \ Academic calendar \
                                </router-link>
                                {{ academicCalendar.title }}
                            </div>
                        </div>

                        <!-- <div class="row">
                            <v-col cols="12" class="text-right">
                                <v-btn
                                    class="text-white ml-2 btn btn-primary"
                                    depressed
                                    @click="assignNewEvent"
                                    v-if="!addNewEvent"
                                >   
                                    <i class="fa fa-plus"></i>
                                    Add new 
                                </v-btn>
                            </v-col>

                            <v-col cols="12" md="4" class="mt-3" v-if="addNewEvent">
                                <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    min-width="auto"
                                    offset-y
                                    transition="scale-transition"
                                    v-model="menu"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            append-icon="mdi-calendar"
                                            dense
                                            label="Search by date"
                                            outlined
                                            readonly 
                                            v-bind="attrs"
                                            v-model="searchDate"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="searchDate"
                                        @input="menu=false" 
                                        @change="searchAcademicEvent"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="12" md="8" class="mt-3" v-if="addNewEvent">
                                <v-autocomplete
                                    outlined 
                                    dense
                                    multiple
                                    v-model="academicCalendarEvent.academic_event_ids"
                                    :items="academicEvents"
                                    item-text="display_text"
                                    item-value="id"
                                    small-chips
                                    placeholder="Type to search"
                                    :search-input.sync="eventSearch"
                                    @input="academicCalendarEvent.academic_event_ids = $event !== null ? $event : ''" 
                                    :loading="isEventLoading"
                                    clearable

                                >
                                    <template v-slot:label>
                                        Add academic event <span class="text-danger">*</span>
                                    </template>
                                </v-autocomplete>
                                <span class="text-danger" v-if="eventNotSelected">This information is required</span>
                            </v-col>
                            
                            <v-col cols="12" md="8" class="mt-3" v-if="addNewEvent">
                                <v-autocomplete
                                    outlined 
                                    dense
                                    multiple
                                    v-model="academicCalendarEvent.academic_event_ids"
                                    :items="academicEvents"
                                    item-text="display_text"
                                    item-value="id"
                                    small-chips
                                    placeholder="Type to search"
                                    @input="academicCalendarEvent.academic_event_ids = $event !== resetSearch ? $event : ''" 
                                    :loading="isEventLoading"
                                    clearable

                                >
                                    <template v-slot:label>
                                        Select academic event <span class="text-danger">*</span>
                                    </template>
                                </v-autocomplete>
                                <span class="text-danger" v-if="eventNotSelected">This information is required</span>
                                <span class="text-danger" v-if="duplicateError">The event is already assigned.</span>
                            </v-col>
                        </div> -->


                        <!-- <div class="row" v-if="addNewEvent">
                            <div class="col-12 text-right">
                                <v-btn
                                    class="text-gray btn btn-standard"
                                    depressed
                                    @click="cancel"
                                >Cancel
                                </v-btn>
                                <v-btn
                                    class="text-white ml-2 btn btn-primary"
                                    depressed
                                    @click="createOrUpdate"
                                    :loading="isBusy"
                                >Save
                                </v-btn>
                            </div>
                        </div> -->

                        <div class="row">
                            <v-col cols="12">
                                <v-sheet height="64" class="border">
                                    <v-toolbar flat>
                                        <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                                            Today
                                        </v-btn>
                                        <v-btn fab text small color="grey darken-2" @click="prev">
                                            <v-icon small> mdi-chevron-left </v-icon>
                                        </v-btn>
                                        <v-btn fab text small color="grey darken-2" @click="next">
                                            <v-icon small> mdi-chevron-right </v-icon>
                                        </v-btn>
                                        <v-toolbar-title v-if="$refs.calendar">
                                            {{ $refs.calendar.title }}
                                        </v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-menu bottom right>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                                                    <span>{{ typeToLabel[type] }}</span>
                                                    <v-icon right> mdi-menu-down </v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item @click="type = 'day'">
                                                    <v-list-item-title>Day</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="type = 'week'">
                                                    <v-list-item-title>Week</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="type = 'month'">
                                                    <v-list-item-title>Month</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="type = '4day'">
                                                    <v-list-item-title>4 days</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </v-toolbar>
                                </v-sheet>
<!-- @click:event="showEvent" -->
                                <v-sheet height="800" class="border">
                                    <v-calendar
                                        ref="calendar"
                                        v-model="value"
                                        :weekdays="weekday"
                                        :type="type"
                                        :events="items"
                                        @click:date="addEvent"
                                        class="text-center"
                                        :event-overlap-threshold="30"
                                    >
                                        <template #day="{ date }">
                                            <v-icon small class="mr-2" @click="addEvent(date)">mdi-plus</v-icon>
                                            <!-- <span>{{ date }}</span> -->
                                        </template>
                                    </v-calendar>

                                    <v-menu
                                        v-model="selectedOpen"
                                        :close-on-content-click="false"
                                        :activator="selectedElement"
                                        offset-x
                                    >
                                        <v-card
                                            color="grey lighten-4"
                                            min-width="350px"
                                            flat
                                        >
                                            <v-toolbar
                                                :color="selectedPopupEvent.color"
                                                dark
                                            >
                                                <v-toolbar-title v-html="selectedPopupEvent.name"></v-toolbar-title>
                                                <v-spacer></v-spacer>
                                            </v-toolbar>
                                            <v-card-text v-if="selectedPopupEvent.type == 'event'">
                                                <span>{{ selectedPopupEvent.start_time }} to {{ selectedPopupEvent.end_time }}</span>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-btn
                                                    text
                                                    class="text-gray btn btn-standard"
                                                    @click="selectedOpen = false"
                                                >
                                                    Close
                                                </v-btn>
                                                <v-btn
                                                    color="red"
                                                    class="btn btn-danger"
                                                    small
                                                    @click="deleteEvent(selectedPopupEvent.id)"
                                                >
                                                    <i class="fa fa-trash-alt"></i>
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-menu>
                                </v-sheet>

                            </v-col>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <create-or-update-event
            ref="create-update-event"
            @refresh="getAcademicCalendarEvents"
        ></create-or-update-event>
    </v-app>

</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import AcademicCalendarEventService from "@/core/services/academic-calendar-event/AcademicCalendarEventService";
import AcademicCalendarService from "@/core/services/academic-calendar/AcademicCalendarService";
import AcademicEventService from "@/core/services/academic-event/AcademicEventService";
import CreateOrUpdateEvent from "./CreateOrUpdateEvent.vue";

const academicCalendarEvent = new AcademicCalendarEventService();
const academicCalendar = new AcademicCalendarService();
const academicEvent = new AcademicEventService();

export default {
    validations: {
        academicCalendarEvent: {
            academic_calendar_id: {required},
            academic_event_ids: {},
        }
    },
    components:{CreateOrUpdateEvent},
    data() {
        return {
            dialog: false,
            isBusy: false,
            academicCalendarEvent: {
                academic_calendar_id: null,
                academic_event_ids: []
            },
            academicCalendar: '',
            academicEvents: [],
            isEventLoading: false,
            eventSearch: '',
            selectedEvent: [],
            eventNotSelected: false,
            value: '',
            weekday: [0, 1, 2, 3, 4, 5, 6],
            weekdays: [
                { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
                { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
                { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
                { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
            ],
            type: 'month',
            types: ['month', 'week', 'day', '4day'],
            events: [],
            selectedPopupEvent: {},
            selectedElement: null,
            selectedOpen: false,
            more: false,
            focus: "",
            typeToLabel: {
                month: "Month",
                week: "Week",
                day: "Day",
                "4day": "4 Days",
            },
            menu: false,
            addNewEvent: false,
            searchDate: '',
            duplicateError: false,
            selectedDate: null
            // date: ''
        };
    },
    mounted(){
        this.getAcademicCalendar();
        this.getAcademicCalendarEvents();
    },
    computed:{
        academicCalendarId(){
            return this.$route.params.academicCalendarId;
        },
        items() {
            const items = [];
            this.events.forEach((item) => {
                items.push({
                    id: item.id,
                    name: item.academic_event_title,
                    start: item.academic_event_start_date,
                    end: item.academic_event_end_date,
                    start_time: item.start_time,
                    end_time: item.end_time,
                    type: item.type,
                    color: item.color,
                    timed: false,
                })
            })
            return items;
        },
    },
    methods: {
        addEvent(clickedDate){
            if(clickedDate.date){
                this.searchDate = clickedDate.date;
            }else{
                this.searchDate = clickedDate;
            }
            this.$refs['create-update-event'].showModal(this.searchDate, this.academicCalendarId)
            // this.assignNewEvent();
        },
        assignNewEvent(){
            this.addNewEvent = true;
        },
        cancel(){
            this.addNewEvent = false;
        },  
        createOrUpdate() {
            if(this.checkIfEventSelected()){
                this.eventNotSelected = false;
                this.createAcademicCalendarEvent();
            }else{
                this.eventNotSelected = true;
            }
        },
        checkIfEventSelected(){
            if(this.academicCalendarEvent.academic_event_ids.length > 0)
                return true;
            return false;
        },  
        createAcademicCalendarEvent(){
            this.academicCalendarEvent.academic_calendar_id = this.academicCalendarId;

            academicCalendarEvent
            .store(this.academicCalendarEvent)
            .then(res => {
                this.isBusy = false;
                this.$snotify.success("Event assigned");
                this.resetForm();
                this.getAcademicCalendar();
                this.getAcademicCalendarEvents();
            })
            .catch((err) => {
                if(err.status == 'duplicate'){
                    this.duplicateError = true;
                    setTimeout(() => {
                        this.duplicateError = false;
                    }, 5000);
                }
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            })
        },
        getAcademicCalendar(){
            academicCalendar
            .show(this.academicCalendarId)
            .then((res) => {
                this.academicCalendar = res.data.academicCalendar;
                this.assignAcademiCalendarId()
            })
            .catch((err) => {

            });
        },
        assignAcademiCalendarId(){
            this.academicCalendarEvent.academic_calendar_id = this.academicCalendarId;
        },
        resetForm(){
            this.dialog= false;
            this.isBusy= false;
            this.academicCalendarEvent= {
                academic_event_ids: []
            };
            this.academicCalendar= '';
            this.academicEvents= [];
            this.isEventLoading= false;
            this.eventSearch= '';
            this.selectedEvent= [];
            this.searchDate = '';
            this.$v.academicCalendarEvent.$reset();
        },

        rnd (a, b) {
            return Math.floor((b - a + 1) * Math.random()) + a
        },

        getAcademicCalendarEvents(){
            let today = new Date();
            let month = today.getMonth();
            month=month+1;

            academicCalendarEvent
            .getByAcademicCalendar(this.academicCalendarId)
            .then((res) => {
                this.events = res.data.data;
            })
            .catch(err => {
                
            });
        },

        showEvent({nativeEvent, event}) {
            const open = () => {
                this.selectedPopupEvent = event
                this.selectedElement = nativeEvent.target
                setTimeout(() => {
                    this.selectedOpen = true
                }, 10)
            }

            if (this.selectedOpen) {
                this.selectedOpen = false
                setTimeout(open, 10)
            } else {
                open()
            }

            nativeEvent.stopPropagation()
        },

        deleteEvent(id){
            this.$confirm({
                message: `Are you sure you want to delete?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        academicCalendarEvent
                        .delete(id)
                        .then(response => {
                            this.$snotify.success('Successfully deleted')
                            this.getAcademicCalendar();
                            this.getAcademicCalendarEvents();
                        })
                        .catch(error => {
                        });
                    }
                }
            });
        },

        setToday() {
            this.focus = "";
        },

        prev() {
            this.$refs.calendar.prev();
        },
        
        next() {
            this.$refs.calendar.next();
        },

        resetSearch(){
            this.academicEvents = [];
            this.academicCalendarEvent= {
                academic_event_ids: []
            };
        },  

        searchAcademicEvent(){
            this.isEventLoading = true;
            
            this.academicEvents = [];
            this.academicCalendarEvent= {
                academic_event_ids: []
            };
            
            let data = {
                date: this.searchDate,
            };

            academicEvent
            .search(data)
            .then((response) => {
                    
                if(response.data.academicEvents.length > 0){
                    response.data.academicEvents.map((item) => {
                        let data = item;

                        data.display_text =
                        item.title +
                        " | " +
                        item.type_text + 
                        " | " +
                        item.start_from + ' to ' + item.end_to;

                        this.academicEvents.push(data);
                    })
                }
            })
            .catch((err) => {

            })
            .finally(() => (this.isEventLoading = false));
        },

        

    },
    watch:{
        // eventSearch(val) {
        //     this.isEventLoading = true;
            
        //     let data = {
        //         val: val,
        //     };

        //     academicEvent
        //     .search(data)
        //     .then((response) => {
        //             response.data.academicEvents.map((item) => {
        //             let data = item;

        //             data.display_text =
        //                 item.title +
        //                 " | " +
        //                 item.type_text + 
        //                 " | " +
        //                 item.start_from + ' to ' + item.end_to;

                    

        //             this.academicEvents.push(data);
        //         });
        //     })
        //     .catch((err) => {

        //     })
        //     .finally(() => (this.isEventLoading = false));
        // },
    }
}
</script>
